import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma'
import frenchkiss from 'frenchkiss'
// import * as Sentry from '@factry/sentry'
import { Context } from '@factry/components'
import { getEquipment } from '@factry/client/equipment'
import { getLastDowntimeEvent } from '@factry/client/downtime_event'
import { getDowntimeCategories } from '@factry/client/downtime_category'
import { settings as operationSettings } from '@factry/operations-manager'
import { settings as orderSettings } from '@factry/order-overview'
import { initialize } from '@factry/base'
import '@factry/order-overview/src/locales'

import App from './App.svelte'
import background from '@factry/user-manager/src/assets/background-3.jpg'
import logo from './assets/helioscreen.png'

// Sentry is temporaly disabled.
// the underlaying issue will be taken care of in this ticket:
// Bug 10216: Update caddy or its config on prod
// Sentry.init({
//   version: import.meta.env.VITE_VERSION,
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   environment: import.meta.env.MODE,
//   proxy: import.meta.env.VITE_BASE_PATH,
// })

operationSettings.helioscreen = true
orderSettings.helioscreen = true

frenchkiss.locale('nl')

initialize({
  background,
  defaultLocale: 'nl',
  availableLocales: ['nl', 'en'],
  basePath: import.meta.env.VITE_BASE_PATH,
  logo,
  logoAlt: 'Helioscreen logo',
  onLogin: async () => {
    const equipment = await getEquipment(Context.getEquipment())
    if (!/^G[0-9]+$/.test(equipment.ID)) {
      window.location.hash = '/operations'
      return
    }

    const event = await getLastDowntimeEvent(Context.getEquipment())
    const categories = await getDowntimeCategories()
    const category = categories.find(c => c.PK === event.CategoryPK)
    if (category && category.ID === '6') window.location.hash = '/looms_setup'
    else window.location.hash = '/operations'
  },
  test: import.meta.env.VITE_TEST,
  versions: import.meta.env.VITE_VERSIONS,
  organization: 'helioscreen',
})

const app = new App({
  target: document.body,
})

export default app
