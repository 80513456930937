<script>
  import Base from '@factry/base'
  import EquipmentOverview from '@factry/equipment-overview'
  import LoomsSetup from '@factry/looms-setup'
  import OperationsManager from '@factry/operations-manager'
  import OrderOverview from '@factry/order-overview'
  import EquipmentManager from '@factry/equipment-manager'
  import TeamRegistration from '@factry/team-manager'
  import DowntimeManager from '@factry/downtime-manager'
  import HistorianManager from '@factry/historian-manager'
  import MessageQueueManager from '@factry/message-queue-manager'
  import MaterialManager from '@factry/material-manager'
  import OrderManager from '@factry/order-manager'
  import { Help } from '@factry/components'

  const links = {
    '/orders': 'http://10.10.10.135:8081/#/order_overview/',
  }
</script>

<Base
  options={[EquipmentOverview,
  LoomsSetup, OrderOverview, OperationsManager,
  EquipmentManager, TeamRegistration, DowntimeManager,
  HistorianManager, MessageQueueManager, MaterialManager, OrderManager
  ]}
/>

<Help
  {links}
/>
